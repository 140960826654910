import { NameSpace,Eng, EditPadText, EditPadIdx, SearchWordConst ,SelectedWord} from "./ConfigConst"
import { createContext, useContext, useReducer } from 'react';


const ConfigContext=createContext(null);
const ConfigDispatchContext = createContext(null);


export function AppConfigProvider({children}){
  const [tasks,dispatch] = useReducer(ConfigReducer,configInitState);
  return(
    <ConfigContext.Provider value = {tasks}>
      <ConfigDispatchContext.Provider value={dispatch}>
        {children}
      </ConfigDispatchContext.Provider>
    </ConfigContext.Provider>
  )
}

export function getAppConfigText(appConfig, id){
  const record = appConfig.find((item) => item.id === id);
  return record.text;
}

export function useAppConfig(){
  return useContext(ConfigContext)
}

export function useAppConfigDispatch(){
  return useContext(ConfigDispatchContext)
}

const configInitState = [
  {id:NameSpace,text:Eng},
  {id:EditPadText,text:""},
  {id:EditPadIdx,text:0},
  {id:SearchWordConst,text:""},
  {id:SelectedWord,text:""}
];


function ConfigReducer(state = configInitState, { type, payload }) {
  switch (type) {
  
  case "add":
    return [...state,{id:payload.id,text:payload.text}]
  case "update":
    return state.map(t=>{
      if(t.id ===payload.id){
        return payload
      }else{
        return t
      }
    });
  default:
    return state
  }
}
