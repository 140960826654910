import React from 'react'
import { Layout, Menu } from 'antd';
import "./AppHeader.css"
import {useState} from 'react';
import NavbarSwitch from './NavbarSwitch';
import {Link} from  "react-router-dom";
const { Header } = Layout;
const AppHeader = () => {

    const [current, setCurrent] = useState('home');
    const shareMe = async ()=>{
      await navigator.clipboard.writeText("Forget perfect spelling! This new way of writing lets you search for words even if the letters are out of order. No more hunting for the exact spelling – just start typing and find what you need. Ready to try it?  Search engines with fuzzy search capabilities are available online. Try it on fuzzydict")
      alert("Share message copied, you can send the share message to your friends now!")
    }

    const items = [
      { key: 'home', label: <Link to= '/'>Home</Link>  }, // Use href for absolute URLs
      { key: 'editor', label: <Link to= '/editor'>Editor</Link> }, // Use href for external links
      { key: 'info', label: <Link to= '/info'>About</Link> }, // Use href for external links
      { key: '', label:<div href='/' onClick={shareMe}>Share</div>}, 
    ];



    const onClick = (e) => {
      setCurrent(e.key);
    };

  return (
    <Layout> 
    <Header  style={{  display: 'flex', justifyItems: 'space-between' }}>
      <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />
      <NavbarSwitch></NavbarSwitch>
    </Header>
    </Layout>
  );
};



export default AppHeader