import React from 'react'
import {  Radio} from 'antd';
import { useAppConfigDispatch,useAppConfig,getAppConfigText } from '../store/ConfigContent/ConfigContext';
import { NameSpace, Eng,EngPro} from '../store/ConfigContent/ConfigConst';

const NavbarSwitch = () => {
    const appConfig = useAppConfig();
    const namespace = getAppConfigText(appConfig,NameSpace)
    const dispatch = useAppConfigDispatch();
    const options = [
      {
        label: 'Eng',
        value: Eng,
      },
      {
        label: 'EngPro',
        value: EngPro,
      },
      // {
      //   label: 'De',
      //   value: 'De',
      //   disabled:true,
      // },
    ];

    const onChange3 = ({ target: { value } }) => {
      //console.log('radio3 checked', value);
      dispatch({type:"update",payload:{id:NameSpace,text:value}})
    };
  return (
    <Radio.Group options={options} onChange={onChange3} value={namespace} optionType="button"  buttonStyle="solid" style={{ backgroundColor: '#fff', paddingTop:'14px',paddingRight:'10px'/* Your desired dark color */ }}/> 
  )
}

export default NavbarSwitch