import React,{ Suspense }  from 'react'

import {  Layout } from 'antd';

import {  Flex } from 'antd';
import "./AppLayout.css";
import AppHeader from './components/AppHeader';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NoPage from './page/NoPage';
import InfoPage from './page/InfoPage';
//import EditPage from './page/EditPage';
import Loading from './page/Loading';
const LazySearchPage = React.lazy(() => import('./page/Searchpage'))
const LazyEditPage = React.lazy(() => import('./page/EditPage'))
const {  Content, Footer } = Layout;




const AppLayout = () => {
    
      return (
        <Flex vertical className="container" >
        <Layout   style={{ minHeight: '100vh' }}>
        <BrowserRouter>
          <AppHeader></AppHeader>
          <Content style={{ padding: 'px 10px',textAlign: 'center' , overflow: 'auto' }}>
            
            <Routes>
              <Route path="/" element={<Suspense fallback={<Loading />}>
                                                  <LazySearchPage />
                                            </Suspense>}/>
              <Route path="/info" element={<InfoPage />} />
              
              <Route path="/editor" element={<Suspense fallback={<Loading />}>
                                                  <LazyEditPage />
                                            </Suspense>} />
              <Route path="/*" element={<NoPage />} />
            </Routes>
      
          </Content>
          </BrowserRouter>
          <div style={{height:'48px'}}></div>
          <Footer style={{ textAlign: 'center' }}>
            FuzzyDict ©{new Date().getFullYear()} Created by Tao
          </Footer>
          
        </Layout>
        </Flex>
      );
}

export default AppLayout

