import React from 'react'
import './Info.css'
const InfoPage = () => {
  return (
    <div style={{paddingTop:'30px'}}>
      <h2>Introducing FuzzyDict: Effortless Word Search!</h2>

        <div>
        <p>Say goodbye to perfect spelling! FuzzyDict lets you find words even if the letters are out of order.</p>
        <p> No more struggling to remember the exact sequence – just type what you can recall and let FuzzyDict do the magic.</p>

        <h3>How it Works:</h3>
        <p>Type in any combination of letters, even if jumbled.</p>
        <p>The FuzzyDict suggests words that match your input.</p>
        <p>Then Click a suggestion to copy it instantly.</p>
        <p>For example, you can search "palestine" with only "plst".</p>
        </div>
        
        <h2>Tips</h2>
        <div>
        <p>Add FuzzyDict to Chrome for quick access! Just click the install button in your address bar.</p>
        <h3>FuzzyDict offers two search modes to fit your needs:</h3>

        <p>Standard Search <b>Eng</b>: Ideal for everyday use, with a comprehensive dictionary of over 10,000 words.</p>
        <p>Pro Search <b>EngPro</b>: Unleash the full potential of FuzzyDict with an expanded dictionary of over 40,000 words, perfect for advanced searches and specialized vocabulary.</p>
        </div>
        <h2>More dictionary to be added?</h2>
        <p>For inquiries about adding new language dictionaries, email us at scuipio44@gmail.com.</p>

    </div>
  )
}

export default InfoPage